import { getCookie, setCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import { useCallback, useContext, useState } from 'react';
import useSWR, { SWRConfiguration } from 'swr';
import Cookies from 'universal-cookie';

import { GetServerSidePropsContext } from 'next';
import { GlobalContext } from '~/contexts/global';
import useUser from '~/lib/user';

import { fetchJson } from './fetch';

import { SUMOTEST_CONVERSION_ENDPOINT } from '~/constants/experiments';

// Cookie constants
const EXP_PREFIX = '__experiment_';
const NOT_ENROLLED_EXP_PREFIX = '__experiment_not_enrolled_';
const COOKIE_EXP_TIME = 60;

const cookies = new Cookies();

export interface UseExperimentProps {
  variant: string;
  isLoading?: boolean;
  isError?: boolean;
}

export interface NonEnrollExperimentProps {
  variants: Record<string, string>;
  isLoading: boolean;
  isError: boolean;
}

export async function enroll(
  experiment: string,
  context: GetServerSidePropsContext,
) {
  const { req, res, query } = context;

  // pull in query parameter behavior
  const override = query[`sumotest-${experiment}`] || undefined;
  const reset = String(query[`sumotest-reset-${experiment}`]) === '1';
  const cookieName = EXP_PREFIX + experiment;

  // load variant from SSR request cookie if no override exists
  const cookieVariant = override || getCookie(cookieName, { req });

  // if we have an override or cookie return the variant
  if (cookieVariant) {
    return cookieVariant;
  }

  let expUrl = `/api/sumotest/enroll/?experiment=${experiment}`;
  if (expUrl && reset) expUrl += `&sumotest-reset-${experiment}=1`;

  const { variant } = await fetchJson(expUrl, {
    headers: {
      cookie: req.headers.cookie,
    },
  });

  // pass the cookie variant back over SSR so browser has it immediately
  if (variant) {
    setCookie(cookieName, variant, {
      path: '/',
      req,
      res,
      maxAge: COOKIE_EXP_TIME,
    });
  }

  return variant;
}

export function useExperiment(
  experiment: string,
  skipEnroll: boolean = false,
  fallbackData?: UseExperimentProps,
  revalidateIfStale?: boolean,
): UseExperimentProps {
  const { enrolledExperiments } = useContext(GlobalContext);
  const defaultValue = enrolledExperiments?.[experiment];
  const { isLoading: isSessionLoading } = useUser();
  const router = useRouter();

  // pull in query parameter behavior
  const override = router.query[`sumotest-${experiment}`];
  const reset = String(router.query[`sumotest-reset-${experiment}`]) === '1';
  const cookieName = EXP_PREFIX + experiment;

  // load variant from cookie if no override exists
  const cookieVariant = override ?? cookies.get(cookieName);

  const swrOptions: SWRConfiguration = {
    errorRetryCount: 1, // Reduce DDOS on backend even more if it's down
  };

  if (
    typeof fallbackData !== 'undefined' ||
    typeof defaultValue !== 'undefined' ||
    cookieVariant
  ) {
    swrOptions.fallbackData = {
      variant: fallbackData?.variant || defaultValue || cookieVariant,
    };
  }

  // useful for experiments that are in components that has a lot of re-renders
  if (revalidateIfStale) {
    swrOptions.revalidateIfStale = true;
  }

  // load from SWR (if cookie, don't load from SWR)
  // Split to avoid nesting ternary
  let expUrl =
    skipEnroll || isSessionLoading
      ? null
      : `/api/sumotest/enroll/?experiment=${experiment}`;
  if (expUrl && reset) expUrl += `&sumotest-reset-${experiment}=1`;

  const { data, error } = useSWR(
    expUrl,
    // if cookie variant is set just return it
    cookieVariant
      ? () =>
          Promise.resolve({
            variant: cookieVariant,
          })
      : fetchJson,
    swrOptions,
  );

  // hook return data
  const { variant } = data || {};

  // set cookie if variant is set and cookie is not set
  if (variant && !cookieVariant) {
    cookies.set(cookieName, variant, {
      path: '/',
      maxAge: COOKIE_EXP_TIME,
    });
  }

  return {
    variant,
    isLoading: !error && !data,
    isError: error,
  };
}

// This is being phased out in favor of enrolledExperiments in staticProps.
// This can be used for nextjs pages that cannot utilize enrolledExperiments.
export function useNonEnrollExperiment(
  slugs: string | Array<string>,
  override: boolean = false,
): NonEnrollExperimentProps {
  const { isLoading: isSessionLoading } = useUser();
  const slugsList = !Array.isArray(slugs) ? [slugs] : slugs;
  const expSlugsToFetch = [] as Array<string>;
  const variants = {} as Record<string, string>;

  // If any of the experiments are already enrolled, use that value instead of fetching
  slugsList.forEach((slug) => {
    const existExp =
      cookies.get(EXP_PREFIX + slug) ||
      cookies.get(NOT_ENROLLED_EXP_PREFIX + slug);

    if (existExp) {
      variants[slug] = existExp;
    } else {
      expSlugsToFetch.push(slug);
    }
  });

  // If override, don't fetch and use values found in the cookies
  // Do not fetch if session is not loaded or no slugs to fetch
  const expUrl =
    override || isSessionLoading || !expSlugsToFetch.length
      ? null
      : `/api/sumotest/get_variants/?experiments=${expSlugsToFetch.join('|')}`;

  const { data, error } = useSWR(expUrl, fetchJson, {
    errorRetryCount: 1, // Reduce DDOS on backend even more if it's down
  });
  const fetchedVariants = data?.data ?? {};

  if (override) {
    return {
      variants,
      isLoading: false,
      isError: false,
    };
  }

  // Only add variants and create cookies for the experiments that we fetched
  Object.keys(fetchedVariants).forEach((key) => {
    const variantValue = fetchedVariants[key];

    variants[key] = variantValue;
    cookies.set(NOT_ENROLLED_EXP_PREFIX + key, variantValue, {
      path: '/',
      maxAge: COOKIE_EXP_TIME,
    });
  });

  return {
    variants,
    isLoading: !error && !data,
    isError: error,
  };
}

export function useConvertMetrics() {
  const [error, setError] = useState();

  const convert = useCallback(async (metrics: Record<string, number>) => {
    fetchJson(SUMOTEST_CONVERSION_ENDPOINT, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      include: 'credentials',
      body: JSON.stringify({ metrics }),
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        setError(error);
      });
  }, []);

  return {
    convert,
    conversionError: error,
  };
}
