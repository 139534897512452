import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faBookmark } from '@fortawesome/free-regular-svg-icons';
import { StripeElementsOptionsMode } from '@stripe/stripe-js';

export enum SimpleButtonTypes {
  applyCoupon = 'applyCoupon',
  remove = 'remove',
  removeSavedDeal = 'removeSavedDeal',
  removeFromCart = 'removeFromCart',
  addSavedDeal = 'addSavedDeal',
}
export enum SimpleButtonSizes {
  sm = 'sm',
  md = 'md',
  lg = 'lg',
}

export type ScreenSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';

export const SIMPLE_BUTTON_TYPES = {
  applyCoupon: {
    text: 'Apply a coupon',
    icon: faPlus,
    color: 'text-bolt',
  },
  remove: {
    text: 'Remove coupon',
    icon: faTrash,
    color: 'text-brick',
  },
  removeSavedDeal: {
    text: 'Remove from saved',
    icon: faTrash,
    color: 'text-brick',
  },
  removeFromCart: {
    text: 'Remove',
    icon: faTrash,
    color: 'text-brick',
  },
  addSavedDeal: {
    text: 'Save for later',
    icon: faBookmark,
    color: 'text-bolt',
  },
};

export const DEFAULT_SWR_OPTIONS = {
  errorRetryCount: 2,
  dedupingInterval: 3000,
};

export const AFFILIATE_URL =
  'https://go.appsumo.com/become-appsumo-affiliate?ref=account_menu';

export const BUY_PLUS_URL = '/products/plus-2019/buy/';

export const STRIPE_PUBLIC_KEY =
  process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY ?? '';

export const STRIPE_OPTIONS_OBJECT = {
  mode: 'payment',
  currency: 'usd',
  amount: 50, // Minimum amount to make a purchase
  paymentMethodCreation: 'manual',
  // Fully customizable with appearance API.
  appearance: {
    /*...*/
  },
} as StripeElementsOptionsMode;

export const START_EMAIL_VERIFICATION_DATE = '2023-08-27';

export enum LAYOUT_TYPES {
  CART = 'cart',
  CHECKOUT_COMPLETE = 'checkoutComplete',
  REDEMPTION = 'redemption',
}

// Share types
export enum ShareType {
  PDP = 'product_detail',
  POST_PURCHASE = 'order_confirmation',
  REDEMPTION = 'product_redemption',
}

export enum SocialShareType {
  share_link = 1,
  email = 2,
  facebook = 3,
  linkedin = 4,
  x = 5,
}

export const MIN_7_CHAR_INT = 2176782336;
export const MAX_6_CHAR_INT = MIN_7_CHAR_INT - 1;

export const KLAVIYO_SMS_ENABLED_COUNTRY_CODES = [
  'US', // United States
  'CA', // Canada
  'GB', // United Kingdom
  'IE', // Ireland
  'DE', // Germany
  'NL', // Netherlands
  'NZ', // New Zealand
];

export const COUNTRIES_WITH_UNICODE_FLAGS = [
  { value: 'US', label: 'United States', selectedLabel: '🇺🇸' },
  { value: 'AF', label: 'Afghanistan', selectedLabel: '🇦🇫' },
  { value: 'AX', label: 'Åland Islands', selectedLabel: '🇦🇽' },
  { value: 'AL', label: 'Albania', selectedLabel: '🇦🇱' },
  { value: 'DZ', label: 'Algeria', selectedLabel: '🇩🇿' },
  { value: 'AD', label: 'Andorra', selectedLabel: '🇦🇩' },
  { value: 'AO', label: 'Angola', selectedLabel: '🇦🇴' },
  { value: 'AI', label: 'Anguilla', selectedLabel: '🇦🇮' },
  { value: 'AQ', label: 'Antarctica', selectedLabel: '🇦🇶' },
  { value: 'AG', label: 'Antigua & Barbuda', selectedLabel: '🇦🇬' },
  { value: 'AR', label: 'Argentina', selectedLabel: '🇦🇷' },
  { value: 'AM', label: 'Armenia', selectedLabel: '🇦🇲' },
  { value: 'AW', label: 'Aruba', selectedLabel: '🇦🇼' },
  { value: 'AU', label: 'Australia', selectedLabel: '🇦🇺' },
  { value: 'AT', label: 'Austria', selectedLabel: '🇦🇹' },
  { value: 'AZ', label: 'Azerbaijan', selectedLabel: '🇦🇿' },
  { value: 'BS', label: 'Bahamas', selectedLabel: '🇧🇸' },
  { value: 'BH', label: 'Bahrain', selectedLabel: '🇧🇭' },
  { value: 'BD', label: 'Bangladesh', selectedLabel: '🇧🇩' },
  { value: 'BB', label: 'Barbados', selectedLabel: '🇧🇧' },
  { value: 'BY', label: 'Belarus', selectedLabel: '🇧🇾' },
  { value: 'BE', label: 'Belgium', selectedLabel: '🇧🇪' },
  { value: 'BZ', label: 'Belize', selectedLabel: '🇧🇿' },
  { value: 'BJ', label: 'Benin', selectedLabel: '🇧🇯' },
  { value: 'BM', label: 'Bermuda', selectedLabel: '🇧🇲' },
  { value: 'BT', label: 'Bhutan', selectedLabel: '🇧🇹' },
  { value: 'BO', label: 'Bolivia', selectedLabel: '🇧🇴' },
  { value: 'BA', label: 'Bosnia & Herzegovina', selectedLabel: '🇧🇦' },
  { value: 'BW', label: 'Botswana', selectedLabel: '🇧🇼' },
  { value: 'BV', label: 'Bouvet Island', selectedLabel: '🇧🇻' },
  { value: 'BR', label: 'Brazil', selectedLabel: '🇧🇷' },
  { value: 'IO', label: 'British Indian Ocean Territory', selectedLabel: '🇮🇴' },
  { value: 'VG', label: 'British Virgin Islands', selectedLabel: '🇻🇬' },
  { value: 'BN', label: 'Brunei', selectedLabel: '🇧🇳' },
  { value: 'BG', label: 'Bulgaria', selectedLabel: '🇧🇬' },
  { value: 'BF', label: 'Burkina Faso', selectedLabel: '🇧🇫' },
  { value: 'BI', label: 'Burundi', selectedLabel: '🇧🇮' },
  { value: 'KH', label: 'Cambodia', selectedLabel: '🇰🇭' },
  { value: 'CM', label: 'Cameroon', selectedLabel: '🇨🇲' },
  { value: 'CA', label: 'Canada', selectedLabel: '🇨🇦' },
  { value: 'CV', label: 'Cape Verde', selectedLabel: '🇨🇻' },
  { value: 'BQ', label: 'Caribbean Netherlands', selectedLabel: '🇧🇶' },
  { value: 'KY', label: 'Cayman Islands', selectedLabel: '🇰🇾' },
  { value: 'CF', label: 'Central African Republic', selectedLabel: '🇨🇫' },
  { value: 'TD', label: 'Chad', selectedLabel: '🇹🇩' },
  { value: 'CL', label: 'Chile', selectedLabel: '🇨🇱' },
  { value: 'CN', label: 'China', selectedLabel: '🇨🇳' },
  { value: 'CO', label: 'Colombia', selectedLabel: '🇨🇴' },
  { value: 'KM', label: 'Comoros', selectedLabel: '🇰🇲' },
  { value: 'CG', label: 'Congo - Brazzaville', selectedLabel: '🇨🇬' },
  { value: 'CD', label: 'Congo - Kinshasa', selectedLabel: '🇨🇩' },
  { value: 'CK', label: 'Cook Islands', selectedLabel: '🇨🇰' },
  { value: 'CR', label: 'Costa Rica', selectedLabel: '🇨🇷' },
  { value: 'CI', label: "Côte d'Ivoire", selectedLabel: '🇨🇮' },
  { value: 'HR', label: 'Croatia', selectedLabel: '🇭🇷' },
  { value: 'CW', label: 'Curaçao', selectedLabel: '🇨🇼' },
  { value: 'CY', label: 'Cyprus', selectedLabel: '🇨🇾' },
  { value: 'CZ', label: 'Czechia', selectedLabel: '🇨🇿' },
  { value: 'DK', label: 'Denmark', selectedLabel: '🇩🇰' },
  { value: 'DJ', label: 'Djibouti', selectedLabel: '🇩🇯' },
  { value: 'DM', label: 'Dominica', selectedLabel: '🇩🇲' },
  { value: 'DO', label: 'Dominican Republic', selectedLabel: '🇩🇴' },
  { value: 'EC', label: 'Ecuador', selectedLabel: '🇪🇨' },
  { value: 'EG', label: 'Egypt', selectedLabel: '🇪🇬' },
  { value: 'SV', label: 'El Salvador', selectedLabel: '🇸🇻' },
  { value: 'GQ', label: 'Equatorial Guinea', selectedLabel: '🇬🇶' },
  { value: 'ER', label: 'Eritrea', selectedLabel: '🇪🇷' },
  { value: 'EE', label: 'Estonia', selectedLabel: '🇪🇪' },
  { value: 'SZ', label: 'Eswatini', selectedLabel: '🇸🇿' },
  { value: 'ET', label: 'Ethiopia', selectedLabel: '🇪🇹' },
  { value: 'FK', label: 'Falkland Islands', selectedLabel: '🇫🇰' },
  { value: 'FO', label: 'Faroe Islands', selectedLabel: '🇫🇴' },
  { value: 'FJ', label: 'Fiji', selectedLabel: '🇫🇯' },
  { value: 'FI', label: 'Finland', selectedLabel: '🇫🇮' },
  { value: 'FR', label: 'France', selectedLabel: '🇫🇷' },
  { value: 'GF', label: 'French Guiana', selectedLabel: '🇬🇫' },
  { value: 'PF', label: 'French Polynesia', selectedLabel: '🇵🇫' },
  { value: 'TF', label: 'French Southern Territories', selectedLabel: '🇹🇫' },
  { value: 'GA', label: 'Gabon', selectedLabel: '🇬🇦' },
  { value: 'GM', label: 'Gambia', selectedLabel: '🇬🇲' },
  { value: 'GE', label: 'Georgia', selectedLabel: '🇬🇪' },
  { value: 'DE', label: 'Germany', selectedLabel: '🇩🇪' },
  { value: 'GH', label: 'Ghana', selectedLabel: '🇬🇭' },
  { value: 'GI', label: 'Gibraltar', selectedLabel: '🇬🇮' },
  { value: 'GR', label: 'Greece', selectedLabel: '🇬🇷' },
  { value: 'GL', label: 'Greenland', selectedLabel: '🇬🇱' },
  { value: 'GD', label: 'Grenada', selectedLabel: '🇬🇩' },
  { value: 'GP', label: 'Guadeloupe', selectedLabel: '🇬🇵' },
  { value: 'GU', label: 'Guam', selectedLabel: '🇬🇺' },
  { value: 'GT', label: 'Guatemala', selectedLabel: '🇬🇹' },
  { value: 'GG', label: 'Guernsey', selectedLabel: '🇬🇬' },
  { value: 'GN', label: 'Guinea', selectedLabel: '🇬🇳' },
  { value: 'GW', label: 'Guinea-Bissau', selectedLabel: '🇬🇼' },
  { value: 'GY', label: 'Guyana', selectedLabel: '🇬🇾' },
  { value: 'HT', label: 'Haiti', selectedLabel: '🇭🇹' },
  { value: 'HN', label: 'Honduras', selectedLabel: '🇭🇳' },
  { value: 'HK', label: 'Hong Kong SAR China', selectedLabel: '🇭🇰' },
  { value: 'HU', label: 'Hungary', selectedLabel: '🇭🇺' },
  { value: 'IS', label: 'Iceland', selectedLabel: '🇮🇸' },
  { value: 'IN', label: 'India', selectedLabel: '🇮🇳' },
  { value: 'ID', label: 'Indonesia', selectedLabel: '🇮🇩' },
  { value: 'IQ', label: 'Iraq', selectedLabel: '🇮🇶' },
  { value: 'IE', label: 'Ireland', selectedLabel: '🇮🇪' },
  { value: 'IM', label: 'Isle of Man', selectedLabel: '🇮🇲' },
  { value: 'IL', label: 'Israel', selectedLabel: '🇮🇱' },
  { value: 'IT', label: 'Italy', selectedLabel: '🇮🇹' },
  { value: 'JM', label: 'Jamaica', selectedLabel: '🇯🇲' },
  { value: 'JP', label: 'Japan', selectedLabel: '🇯🇵' },
  { value: 'JE', label: 'Jersey', selectedLabel: '🇯🇪' },
  { value: 'JO', label: 'Jordan', selectedLabel: '🇯🇴' },
  { value: 'KZ', label: 'Kazakhstan', selectedLabel: '🇰🇿' },
  { value: 'KE', label: 'Kenya', selectedLabel: '🇰🇪' },
  { value: 'KI', label: 'Kiribati', selectedLabel: '🇰🇮' },
  { value: 'KW', label: 'Kuwait', selectedLabel: '🇰🇼' },
  { value: 'KG', label: 'Kyrgyzstan', selectedLabel: '🇰🇬' },
  { value: 'LA', label: 'Laos', selectedLabel: '🇱🇦' },
  { value: 'LV', label: 'Latvia', selectedLabel: '🇱🇻' },
  { value: 'LB', label: 'Lebanon', selectedLabel: '🇱🇧' },
  { value: 'LS', label: 'Lesotho', selectedLabel: '🇱🇸' },
  { value: 'LR', label: 'Liberia', selectedLabel: '🇱🇷' },
  { value: 'LY', label: 'Libya', selectedLabel: '🇱🇾' },
  { value: 'LI', label: 'Liechtenstein', selectedLabel: '🇱🇮' },
  { value: 'LT', label: 'Lithuania', selectedLabel: '🇱🇹' },
  { value: 'LU', label: 'Luxembourg', selectedLabel: '🇱🇺' },
  { value: 'MO', label: 'Macao SAR China', selectedLabel: '🇲🇴' },
  { value: 'MG', label: 'Madagascar', selectedLabel: '🇲🇬' },
  { value: 'MW', label: 'Malawi', selectedLabel: '🇲🇼' },
  { value: 'MY', label: 'Malaysia', selectedLabel: '🇲🇾' },
  { value: 'MV', label: 'Maldives', selectedLabel: '🇲🇻' },
  { value: 'ML', label: 'Mali', selectedLabel: '🇲🇱' },
  { value: 'MT', label: 'Malta', selectedLabel: '🇲🇹' },
  { value: 'MQ', label: 'Martinique', selectedLabel: '🇲🇶' },
  { value: 'MR', label: 'Mauritania', selectedLabel: '🇲🇷' },
  { value: 'MU', label: 'Mauritius', selectedLabel: '🇲🇺' },
  { value: 'YT', label: 'Mayotte', selectedLabel: '🇾🇹' },
  { value: 'MX', label: 'Mexico', selectedLabel: '🇲🇽' },
  { value: 'MD', label: 'Moldova', selectedLabel: '🇲🇩' },
  { value: 'MC', label: 'Monaco', selectedLabel: '🇲🇨' },
  { value: 'MN', label: 'Mongolia', selectedLabel: '🇲🇳' },
  { value: 'ME', label: 'Montenegro', selectedLabel: '🇲🇪' },
  { value: 'MS', label: 'Montserrat', selectedLabel: '🇲🇸' },
  { value: 'MA', label: 'Morocco', selectedLabel: '🇲🇦' },
  { value: 'MZ', label: 'Mozambique', selectedLabel: '🇲🇿' },
  { value: 'MM', label: 'Myanmar (Burma)', selectedLabel: '🇲🇲' },
  { value: 'NA', label: 'Namibia', selectedLabel: '🇳🇦' },
  { value: 'NR', label: 'Nauru', selectedLabel: '🇳🇷' },
  { value: 'NP', label: 'Nepal', selectedLabel: '🇳🇵' },
  { value: 'NL', label: 'Netherlands', selectedLabel: '🇳🇱' },
  { value: 'NC', label: 'New Caledonia', selectedLabel: '🇳🇨' },
  { value: 'NZ', label: 'New Zealand', selectedLabel: '🇳🇿' },
  { value: 'NI', label: 'Nicaragua', selectedLabel: '🇳🇮' },
  { value: 'NE', label: 'Niger', selectedLabel: '🇳🇪' },
  { value: 'NG', label: 'Nigeria', selectedLabel: '🇳🇬' },
  { value: 'NU', label: 'Niue', selectedLabel: '🇳🇺' },
  { value: 'MK', label: 'North Macedonia', selectedLabel: '🇲🇰' },
  { value: 'NO', label: 'Norway', selectedLabel: '🇳🇴' },
  { value: 'OM', label: 'Oman', selectedLabel: '🇴🇲' },
  { value: 'PK', label: 'Pakistan', selectedLabel: '🇵🇰' },
  { value: 'PS', label: 'Palestinian Territories', selectedLabel: '🇵🇸' },
  { value: 'PA', label: 'Panama', selectedLabel: '🇵🇦' },
  { value: 'PG', label: 'Papua New Guinea', selectedLabel: '🇵🇬' },
  { value: 'PY', label: 'Paraguay', selectedLabel: '🇵🇾' },
  { value: 'PE', label: 'Peru', selectedLabel: '🇵🇪' },
  { value: 'PH', label: 'Philippines', selectedLabel: '🇵🇭' },
  { value: 'PN', label: 'Pitcairn Islands', selectedLabel: '🇵🇳' },
  { value: 'PL', label: 'Poland', selectedLabel: '🇵🇱' },
  { value: 'PT', label: 'Portugal', selectedLabel: '🇵🇹' },
  { value: 'PR', label: 'Puerto Rico', selectedLabel: '🇵🇷' },
  { value: 'QA', label: 'Qatar', selectedLabel: '🇶🇦' },
  { value: 'RE', label: 'Réunion', selectedLabel: '🇷🇪' },
  { value: 'RO', label: 'Romania', selectedLabel: '🇷🇴' },
  { value: 'RU', label: 'Russia', selectedLabel: '🇷🇺' },
  { value: 'RW', label: 'Rwanda', selectedLabel: '🇷🇼' },
  { value: 'WS', label: 'Samoa', selectedLabel: '🇼🇸' },
  { value: 'SM', label: 'San Marino', selectedLabel: '🇸🇲' },
  { value: 'ST', label: 'São Tomé & Príncipe', selectedLabel: '🇸🇹' },
  { value: 'SA', label: 'Saudi Arabia', selectedLabel: '🇸🇦' },
  { value: 'SN', label: 'Senegal', selectedLabel: '🇸🇳' },
  { value: 'RS', label: 'Serbia', selectedLabel: '🇷🇸' },
  { value: 'SC', label: 'Seychelles', selectedLabel: '🇸🇨' },
  { value: 'SL', label: 'Sierra Leone', selectedLabel: '🇸🇱' },
  { value: 'SG', label: 'Singapore', selectedLabel: '🇸🇬' },
  { value: 'SX', label: 'Sint Maarten', selectedLabel: '🇸🇽' },
  { value: 'SK', label: 'Slovakia', selectedLabel: '🇸🇰' },
  { value: 'SI', label: 'Slovenia', selectedLabel: '🇸🇮' },
  { value: 'SB', label: 'Solomon Islands', selectedLabel: '🇸🇧' },
  { value: 'SO', label: 'Somalia', selectedLabel: '🇸🇴' },
  { value: 'ZA', label: 'South Africa', selectedLabel: '🇿🇦' },
  {
    value: 'GS',
    label: 'South Georgia & South Sandwich Islands',
    selectedLabel: '🇬🇸',
  },
  { value: 'KR', label: 'South Korea', selectedLabel: '🇰🇷' },
  { value: 'SS', label: 'South Sudan', selectedLabel: '🇸🇸' },
  { value: 'ES', label: 'Spain', selectedLabel: '🇪🇸' },
  { value: 'LK', label: 'Sri Lanka', selectedLabel: '🇱🇰' },
  { value: 'BL', label: 'St. Barthélemy', selectedLabel: '🇧🇱' },
  { value: 'SH', label: 'St. Helena', selectedLabel: '🇸🇭' },
  { value: 'KN', label: 'St. Kitts & Nevis', selectedLabel: '🇰🇳' },
  { value: 'LC', label: 'St. Lucia', selectedLabel: '🇱🇨' },
  { value: 'MF', label: 'St. Martin', selectedLabel: '🇲🇫' },
  { value: 'PM', label: 'St. Pierre & Miquelon', selectedLabel: '🇵🇲' },
  { value: 'VC', label: 'St. Vincent & Grenadines', selectedLabel: '🇻🇨' },
  { value: 'SR', label: 'Suriname', selectedLabel: '🇸🇷' },
  { value: 'SJ', label: 'Svalbard & Jan Mayen', selectedLabel: '🇸🇯' },
  { value: 'SE', label: 'Sweden', selectedLabel: '🇸🇪' },
  { value: 'CH', label: 'Switzerland', selectedLabel: '🇨🇭' },
  { value: 'TW', label: 'Taiwan', selectedLabel: '🇹🇼' },
  { value: 'TJ', label: 'Tajikistan', selectedLabel: '🇹🇯' },
  { value: 'TZ', label: 'Tanzania', selectedLabel: '🇹🇿' },
  { value: 'TH', label: 'Thailand', selectedLabel: '🇹🇭' },
  { value: 'TL', label: 'Timor-Leste', selectedLabel: '🇹🇱' },
  { value: 'TG', label: 'Togo', selectedLabel: '🇹🇬' },
  { value: 'TK', label: 'Tokelau', selectedLabel: '🇹🇰' },
  { value: 'TO', label: 'Tonga', selectedLabel: '🇹🇴' },
  { value: 'TT', label: 'Trinidad & Tobago', selectedLabel: '🇹🇹' },
  { value: 'TN', label: 'Tunisia', selectedLabel: '🇹🇳' },
  { value: 'TR', label: 'Turkey', selectedLabel: '🇹🇷' },
  { value: 'TM', label: 'Turkmenistan', selectedLabel: '🇹🇲' },
  { value: 'TC', label: 'Turks & Caicos Islands', selectedLabel: '🇹🇨' },
  { value: 'TV', label: 'Tuvalu', selectedLabel: '🇹🇻' },
  { value: 'UG', label: 'Uganda', selectedLabel: '🇺🇬' },
  { value: 'UA', label: 'Ukraine', selectedLabel: '🇺🇦' },
  { value: 'AE', label: 'United Arab Emirates', selectedLabel: '🇦🇪' },
  { value: 'GB', label: 'United Kingdom', selectedLabel: '🇬🇧' },
  { value: 'UY', label: 'Uruguay', selectedLabel: '🇺🇾' },
  { value: 'UZ', label: 'Uzbekistan', selectedLabel: '🇺🇿' },
  { value: 'VU', label: 'Vanuatu', selectedLabel: '🇻🇺' },
  { value: 'VA', label: 'Vatican City', selectedLabel: '🇻🇦' },
  { value: 'VE', label: 'Venezuela', selectedLabel: '🇻🇪' },
  { value: 'VN', label: 'Vietnam', selectedLabel: '🇻🇳' },
  { value: 'WF', label: 'Wallis & Futuna', selectedLabel: '🇼🇫' },
  { value: 'EH', label: 'Western Sahara', selectedLabel: '🇪🇭' },
  { value: 'YE', label: 'Yemen', selectedLabel: '🇾🇪' },
  { value: 'ZM', label: 'Zambia', selectedLabel: '🇿🇲' },
  { value: 'ZW', label: 'Zimbabwe', selectedLabel: '🇿🇼' },
];

export const SUPPORT_EMAIL = 'support@appsumo.com';

export const SOCIAL_SHARE_COPY_NAME = 'copy';
