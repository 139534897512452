import {
  faComment,
  faExchangeAlt,
  faExclamationCircle,
  faExternalLinkAlt,
  faFileInvoice,
  faInfoCircle,
  faRandom,
  faUndoAlt,
  faVideo,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';

import {
  IPurchasedProduct,
  IProductAction,
  SubscriptionStatus,
  AlertMessageType,
  AlertMessageConfigProps,
} from '~/types/account';

import WarningIcon from '~/public/font-awesome/exclamation-triangle-solid.png';
import ErrorIcon from '~/public/font-awesome/exclamation-circle-red.png';
import InfoIcon from '~/public/font-awesome/question-circle.png';

export enum ACCOUNT_PAGES {
  PROFILE = 'profile',
  PRODUCTS = 'products',
  PAYMENTS = 'payments',
  CREDITS = 'credits',
  PLUS = 'plus',
  REDEMPTION = 'redemption',
}

export enum PLUS_SUBSCRIPTION_STATUS {
  ACTIVE = 'active',
  CANCELED = 'canceled',
  INACTIVE = 'inactive',

  // Frontend only
  ENDING = 'ending',
  EXPIRED = 'expired',
}

export enum PRODUCTS_STATUS {
  TRANSFERRED = 'transferred',
  REDEEMED = 'redeemed',
  UNREDEEMED = 'unredeemed',
  OUTDATED = 'outdated',
  REFUNDED = 'refunded',
  ACTIVATED = 'activated',
  INACTIVE = 'inactive',
  EXPIRED = 'expired',
}

export enum PRODUCT_ACTIONS {
  CHANGE_PLAN = 'change-plan',
  LEAVE_REVIEW = 'leave-review',
  PRODUCT_PAGE = 'product-page',
  REDEEM = 'redeem',
  REDEMPTION = 'redemption',
  REFUND = 'refund',
  TRANSFER = 'transfer',
  VIEW_INVOICE = 'view-invoice',
  WATCH_WEBINAR = 'watch-webinar',
  VIEW_MEMBERSHIP = 'view-membership',
}

export enum ACCOUNT_PRODUCT_FILTERS {
  ACTIVE = 'active',
  NOT_REDEEMED = 'not-redeemed',
  OUTDATED = 'outdated',
  REFUNDED = 'refunded',
  TRANSFERRED = 'transferred',
}

export enum SOCIAL_ACCOUNT_TYPES {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  LINKEDIN = 'linkedin',
  TWITTER = 'twitter',
  YOUTUBE = 'youtube',
  TIKTOK = 'tiktok',
}

export const FILTER_OPTIONS = [
  { value: ACCOUNT_PRODUCT_FILTERS.ACTIVE, label: 'Active' },
  { value: ACCOUNT_PRODUCT_FILTERS.NOT_REDEEMED, label: 'Not redeemed' },
  { value: ACCOUNT_PRODUCT_FILTERS.OUTDATED, label: 'Outdated' },
  { value: ACCOUNT_PRODUCT_FILTERS.REFUNDED, label: 'Refunded' },
  // { value: 'transferred', label: 'Transferred' }, TBD on this one
];

export const PRODUCTS_ITEMS_PER_PAGE = 20;
export const PRODUCTS_FILTER_OPTIONS = [
  { value: 'active', display: 'Active' },
  { value: 'not-redeemed', display: 'Not Redeemed' },
  { value: 'outdated', display: 'Outdated' },
  { value: 'refunded', display: 'Refunded' },
];

export enum TAX_TYPE {
  ABN = 'abn',
  DEFAULT = 'default',
}

export enum EMAIL_UPDATE_TYPE {
  VERIFY = 'action_send',
  ADD = 'action_add',
  REMOVE = 'action_remove',
  PRIMARY = 'action_primary',
}

export const ACCOUNT_DATA_URL = '/api/users/account-data';
export const PROFILE_DATA_URL = '/api/account/profile';
export const ACCOUNT_UPDATE_URL = '/api/account/update/';
export const PLUS_API_URL = '/api/account/plus/';
export const PLUS_API_UPDATE_URL = '/api/account/plus/update/';
export const PRODUCTS_API = '/api/v2/account/products/';
export const PRODUCTS_DOWNLOAD_API = '/api/v2/account/products/download/';
export const USER_CREDITS_URL =
  '/api/users/account-credits/?include_coming_soon_coupons=true';
export const ACCOUNT_PM_UPDATE_URL = '/account/cards/update-default/';
export const ACCOUNT_PM_DELETE_URL = '/account/cards/delete/';
export const ACCOUNT_REDEEM_GIFT_URL = '/account/giftcard/redeem/';
export const RESET_PASSWORD_URL = '/account/password/';
export const TRANSFER_API_URL = '/license/activate/';
export const PUBLIC_PROFILE_API_URL = '/api/user/profile/';
export const ACCOUNT_PROFILE_URL = '/account/profile/';
export const PLUS_CANCEL_REASON_API_URL = '/api/cart/friction-reasons/cancel/';
export const PLUS_CANCEL_RESPONSE_API_URL = '/api/cart/cancel-response/';
export const REDEMPTION_REFUND_REASONS_API_URL =
  '/api/cart/friction-reasons/refund/';
export const REDEMPTION_REFUND_RESPONSE_API_URL = '/api/cart/refund-response/';

export const ACCOUNT_ACTIONS_DEFAULT: Record<string, IProductAction> = {
  [PRODUCT_ACTIONS.REDEEM]: {
    label: 'Redemption information',
    value: PRODUCT_ACTIONS.REDEEM,
    img: faInfoCircle,
    disabled: false,
    url: (product: IPurchasedProduct) => `/account/redemption/${product.uuid}/`,
  },
  [PRODUCT_ACTIONS.LEAVE_REVIEW]: {
    label: 'Leave a review',
    value: PRODUCT_ACTIONS.LEAVE_REVIEW,
    img: faComment,
    disabled: false,
    url: (product: IPurchasedProduct) => `/products/${product.slug}/#reviews`,
  },
  [PRODUCT_ACTIONS.VIEW_INVOICE]: {
    label: 'View invoice',
    value: PRODUCT_ACTIONS.VIEW_INVOICE,
    img: faFileInvoice,
    disabled: false,
    url: (product: IPurchasedProduct) =>
      `/account/history/${product.invoice_uuid}/`,
  },
  [PRODUCT_ACTIONS.CHANGE_PLAN]: {
    label: 'Change plan',
    value: PRODUCT_ACTIONS.CHANGE_PLAN,
    img: faExchangeAlt,
    disabled: false,
    url: (product: IPurchasedProduct) =>
      `/account/redemption/${product.uuid}/change-plan/`,
  },
  [PRODUCT_ACTIONS.TRANSFER]: {
    label: 'Transfer Product',
    value: PRODUCT_ACTIONS.TRANSFER,
    img: faRandom,
    disabled: false,
  },
  [PRODUCT_ACTIONS.REFUND]: {
    label: 'Refund',
    value: PRODUCT_ACTIONS.REFUND,
    img: faUndoAlt,
    disabled: false,
    url: (product: IPurchasedProduct) =>
      `/account/redemption/${product.uuid}/#refund-instructions`,
  },
  [PRODUCT_ACTIONS.PRODUCT_PAGE]: {
    label: 'Product details',
    value: PRODUCT_ACTIONS.PRODUCT_PAGE,
    img: faExternalLinkAlt,
    disabled: false,
    url: (product: IPurchasedProduct) => `/products/${product.slug}/`,
  },
  [PRODUCT_ACTIONS.WATCH_WEBINAR]: {
    label: 'Watch webinar',
    value: PRODUCT_ACTIONS.WATCH_WEBINAR,
    img: faVideo,
    disabled: false,
    url: (product: IPurchasedProduct) => product.webinar.url ?? '',
    target: '_blank',
  },
  [PRODUCT_ACTIONS.VIEW_MEMBERSHIP]: {
    label: 'View Membership',
    value: PRODUCT_ACTIONS.VIEW_MEMBERSHIP,
    img: faVideo,
    disabled: false,
    url: () => '/account/plus/',
  },
};

export const ACCOUNT_PLUS_ALERTS: Record<SubscriptionStatus | string, any> = {
  [PLUS_SUBSCRIPTION_STATUS.CANCELED]: {
    bg: 'bg-lemon-light-40',
    text: (date: string) =>
      `You have canceled your membership. Access to your benefits will end on ${date}.`,
    icon: {
      definition: faWarning,
      color: 'text-black-pearl',
    },
  },
  [PLUS_SUBSCRIPTION_STATUS.EXPIRED]: {
    bg: 'bg-brick-light-60',
    text: 'Your credit card is expired. Please update your payment method information.',
    icon: {
      definition: faExclamationCircle,
      color: 'text-brick',
    },
  },
  [PLUS_SUBSCRIPTION_STATUS.INACTIVE]: {
    bg: 'bg-brick-light-60',
    text: 'Your membership is inactive. Renew your membership to continue using your benefits!',
    icon: {
      definition: faExclamationCircle,
      color: 'text-brick',
    },
  },
};

export const PLUS_MEMBERSHIP_STATUSES_COLOR: Record<
  SubscriptionStatus | string,
  any
> = {
  [PLUS_SUBSCRIPTION_STATUS.ACTIVE]: {
    bg: 'bg-dollar',
    color: 'text-midnight',
  },
  [PLUS_SUBSCRIPTION_STATUS.CANCELED]: {
    bg: 'bg-lemon-light-40',
    color: 'text-midnight',
  },
  [PLUS_SUBSCRIPTION_STATUS.INACTIVE]: {
    bg: 'bg-brick-light-60',
    color: 'text-brick',
  },
};

export const ACCOUNT_SOCIAL_BASE_URLS = {
  [SOCIAL_ACCOUNT_TYPES.FACEBOOK]: 'https://www.facebook.com/',
  [SOCIAL_ACCOUNT_TYPES.INSTAGRAM]: 'https://www.instagram.com/',
  [SOCIAL_ACCOUNT_TYPES.LINKEDIN]: 'https://www.linkedin.com/in/',
  [SOCIAL_ACCOUNT_TYPES.TWITTER]: 'https://twitter.com/',
  [SOCIAL_ACCOUNT_TYPES.YOUTUBE]: 'https://www.youtube.com/@',
  [SOCIAL_ACCOUNT_TYPES.TIKTOK]: 'https://www.tiktok.com/@',
};

export const ACCOUNT_PHONE_FIELD_NAME = 'phone.phone_number';

export enum CANCEL_FLOW_STEPS {
  REASON = 'reason',
  PERKS = 'perks',
  FINAL = 'final',
}

export const ACCOUNT_PLUS_CANCEL_PERKS = [
  {
    label: 'Quarterly coupons',
    description: "We're giving Plus members $25 to spend each quarter in 2024",
  },
  {
    label: 'The Sauce',
    description: 'Our exclusive business community',
  },
  {
    label: 'Plus exclusives and AppSumo Originals freebies',
  },
  {
    label: '10% off all purchases',
  },
  {
    label: 'Special events',
    description: 'Last Call, Extended Access, and Plus Day',
  },
  {
    label: 'A masterclass library',
  },
  {
    label: 'VIP Support',
    description: 'half the wait time as general support',
  },
  {
    label: 'Purchase Protection Policy',
    description:
      '12-month full refund gaurantee in the event of a Select product closure',
  },
  {
    label: 'Future perks',
    description:
      "Plus membership ensures you're always at the forefront of our community and enjoy exclusive access to future benefits",
  },
];

export const CARDS_DATA = [
  {
    emoji: '💸',
    title: '$100 in coupons',
    description: {
      line1: '$25 to spend. Every. Single. Quarter.',
      line2: 'This perk is only available to Plus members in 2024.',
    },
    link: {
      url: 'https://help.appsumo.com/article/708-appsumo-plus-coupons',
      text: 'Coupon details',
    },
  },
  {
    emoji: '🤺',
    title: 'Purchase protection',
    description: {
      line1:
        'Shop with confidence: Request a full refund in credits if a Select product closes within 12 months of purchase.',
      line2: '',
    },
    link: {
      url: 'https://help.appsumo.com/article/698-purchase-protection-policy',
      text: 'Plan details',
    },
  },
  {
    emoji: '🤑',
    title: '10% off EVERYTHING',
    description: {
      line1:
        'You love savings. We love savings. So we give you savings on top of your savings.',
      line2: 'Now that’s a big deal.',
    },
    link: {
      url: 'https://help.appsumo.com/article/53-plus-discount',
      text: 'Perk details',
    },
  },
];

export const ALERT_MESSAGE_CONFIG: Record<
  AlertMessageType,
  AlertMessageConfigProps
> = {
  warning: {
    icon: WarningIcon,
    className: 'bg-lemon-light-40',
  },
  error: {
    icon: ErrorIcon,
    className: 'bg-brick-light-40',
  },
  info: {
    icon: InfoIcon,
    className: 'bg-skys-the-limit-light-40/20',
  },
};

export const LICENSE_ACTIVATION_ERROR_MESSAGES: Record<string, string> = {
  PARTNER_URL:
    "Hmm... looks like we can't connect to our partner's site right now. Try activating your product later or contact",
  NOT_CREATED:
    'Sorry, there has been an error with your license, please contact',
  TIMEOUT:
    'Sorry, our servers are under heavy load at the moment and your license has not been created yet. Please check back a little later',
};
